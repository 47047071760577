import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { map, Observable } from 'rxjs';

import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';

import { ResponseWrapper } from '../models/api.model';
import { AppBranchesV1, ApplicationV1, ApplicationV4PagedApiRequestParams, ApplicationV4Response, BranchV2PagedApiRequestParams, BranchV2Response, BuildV1, BuildV1Response, GlobalTestStagesV1RequestParams, TestStageGlobalV1 } from '../models/app-branch-build-teststage.model';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class AppBranchBuildTeststageService {
	readonly #apiBaseUrl = inject(APP_CONFIG)?.uri.apiBaseUrl;
	readonly #httpClient = inject(HttpClient);
	readonly #apiService = inject(ApiService);

	getApplicationsV4(params: ApplicationV4PagedApiRequestParams): Observable<ApplicationV4Response> {
		let httpParams = this.#apiService.createPagedApiHttpParams(params);

		if (Number.isFinite(params.periodFrom)) {
			httpParams = httpParams.append('periodFrom', params.periodFrom.toString());
		}

		if (Number.isFinite(params.periodTo)) {
			httpParams = httpParams.append('periodTo', params.periodTo.toString());
		}

		if (params.type) {
			httpParams = httpParams.append('type', params.type);
		}

		const url = `${this.#apiBaseUrl}v4/apps`;

		return this.#httpClient.get<ApplicationV4Response>(url, { params: httpParams });
	}

	getBranchesByAppNameV2(params: BranchV2PagedApiRequestParams): Observable<BranchV2Response> {
		let httpParams = this.#apiService.createPagedApiHttpParams(params);

		if (Number.isFinite(params.periodFrom)) {
			httpParams = httpParams.append('periodFrom', params.periodFrom.toString());
		}

		if (params.appName) {
			httpParams = httpParams.append('appName', params.appName);
		}

		if (params.itemsToProject) {
			httpParams = httpParams.append('itemsToProject', params.itemsToProject);
		}

		const api = `${this.#apiBaseUrl}v2/branches`;

		return this.#httpClient.get<BranchV2Response>(api, { params: httpParams });
	}

	getApplicationsV1(): Observable<ApplicationV1[]> {
		const api = `${this.#apiBaseUrl}v1/onboarding/apps`;

		return this.#httpClient.get<{ apps: ApplicationV1[] }>(api).pipe(map(data => data.apps));
	}

	getBranchesByAppNameV1(appName: string): Observable<AppBranchesV1> {
		const api = `${this.#apiBaseUrl}v1/onboarding/apps/${encodeURIComponent(appName)}/branches`;

		return this.#httpClient.get<{ branches: AppBranchesV1 }>(api).pipe(map(data => data.branches));
	}

	getBuildsByAppAndBranchV1(appName: string, branchName: string): Observable<BuildV1[]> {
		const api = `${this.#apiBaseUrl}v1/onboarding/apps/${encodeURIComponent(appName)}/branches/${encodeURIComponent(branchName)}/builds`;

		return this.#httpClient.get<BuildV1Response>(api).pipe(map(data => data.builds));
	}

	getGlobalTestStagesV1(params: GlobalTestStagesV1RequestParams): Observable<ResponseWrapper<TestStageGlobalV1>> {
		let httpParams = new HttpParams();

		httpParams = httpParams.append('appName', params.appName);

		if (params.branchName) {
			httpParams = httpParams.append('branchName', params.branchName);
		}

		if (Number.isFinite(params.periodFrom)) {
			httpParams = httpParams.append('periodFrom', params.periodFrom.toString());
		}

		if (Number.isFinite(params.periodTo)) {
			httpParams = httpParams.append('periodTo', params.periodTo.toString());
		}

		if (params.bsid) {
			httpParams = httpParams.append('bsid', params.bsid);
		}

		if (params.withIntegrationStages) {
			httpParams = httpParams.append('withIntegrationStages', params.withIntegrationStages);
		}

		const url = `${this.#apiBaseUrl}v1/global-test-stages`;

		return this.#httpClient.get<ResponseWrapper<TestStageGlobalV1>>(url, { params: httpParams });
	}

	getTestStagesV2(appName: string, branchName?: string, bsid?: string, periodFrom?: number, periodTo?: number): Observable<string[]> {
		let httpParams = new HttpParams();

		httpParams = httpParams.append('appName', appName);

		if (!isNil(branchName)) {
			httpParams = httpParams.append('branchName', branchName);
		}

		if (Number.isFinite(periodFrom)) {
			httpParams = httpParams.append('periodFrom', periodFrom.toString());
		}

		if (Number.isFinite(periodTo)) {
			httpParams = httpParams.append('periodTo', periodTo.toString());
		}

		if (!isNil(bsid)) {
			httpParams = httpParams.append('bsid', bsid);
		}

		const url = `${this.#apiBaseUrl}v2/test-stages`;

		return this.#httpClient.get<string[]>(url, { params: httpParams });
	}
}
