/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

import { TableState } from '@@shared/common';

import { API_CONFIG } from '../../models/api.model';

@Injectable()
export class TableStore extends ComponentStore<TableState> {

	readonly state$ = this.select(state => state);

	constructor() {
		super({
			page: 1,
			pageSize: API_CONFIG.pageSize,
			search: null,
			sortBy: null,
			sortDirection: null
		});
	}

	readonly nextPage = this.updater((state): TableState => ({
		...state,
		page: state.page + 1
	}));

	readonly prevPage = this.updater((state): TableState => ({
		...state,
		page: Math.max(state.page - 1, 1)
	}));

	readonly setPage = this.updater((state, page: number): TableState => ({
		...state,
		page: Math.max(1, page)
	}));

	readonly setFilter = this.updater((state, filter: string): TableState => ({
		...state,
		search: filter,
		page: 1
	}));

	readonly clearFilter = this.updater((state): TableState => ({
		...state,
		search: null,
		page: 1
	}));

	readonly setPageSize = this.updater((state, pageSize: number): TableState => ({
		...state,
		page: 1,
		pageSize
	}));

	readonly setSortColumn = this.updater((state, column: string): TableState => ({
		...state,
		page: 1,
		sortBy: column
	}));

	readonly setSortDirection = this.updater((state, sortDirection: 'asc' | 'desc'): TableState => ({
		...state,
		page: 1,
		sortDirection
	}));

	readonly setSort = this.updater((state, sortParams: { column: string; sortDirection: 'asc' | 'desc' }): TableState => ({
		...state,
		page: 1,
		sortBy: sortParams.column,
		sortDirection: sortParams.sortDirection
	}));
}
