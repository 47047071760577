import { subYears } from 'date-fns';

import { DropdownPagedApiResponseDefaultState, PagedApiRequestParams, pagedApiResponseDefaultState, PagedResponse, PagedResponseWrapper } from './api.model';

export type ApplicationV4Response = PagedResponseWrapper<ApplicationV4, PagedResponse<ApplicationV4>>;
export type BranchV2Response = PagedResponseWrapper<BranchV2, PagedResponse<BranchV2>>;


export interface ApplicationV4 {
	name: string;
}

export interface AppBranchV2 {
	appName: string;
	branchName: string;
}

export interface ApplicationV4PagedApiRequestParams extends PagedApiRequestParams {
	periodFrom?: number;
	periodTo?: number;
	type?: 'component';
}

export enum BranchProjection {
	BOTH = 'both',
	VISIBLE = 'visible',
	HIDDEN = 'hidden'
}

export interface BranchV2PagedApiRequestParams extends PagedApiRequestParams {
	periodFrom?: number;
	appName?: string;
	itemsToProject: BranchProjection;
}
export interface BranchV2 {
	branchName: string;
	lastRun: string;
	isHidden: boolean;
}

export interface BranchV2Request {
	appName: string;
	page: number;
	search: string;
	key?: string;
}
export interface ApplicationV4Request {
	page: number;
	search: string;
	key?: string;
}

export interface AppBranchItem {
	app: ApplicationV4;
	branch: BranchV2;
	key: string;
}



// V1 API Models
export enum AppType {
	Component,
	Inegrated
}

export interface AppBranchesV1 {
	appName: string;
	branchNames: string[];
}

export interface ApplicationV1 {
	appName: string;
	appType: AppType;
	customerId: string;
	etlId: string;
	generated: number;
	hidden: boolean;
	isHidden: boolean;
	lastRun: string;
}

export interface BranchV1 {
	appName: string;
	branchName: string;
	customerId: string;
	generated: number;
	hidden: boolean;
	isHidden: boolean;
	lastRun: Date;
}

export interface BuildV1Response {
	appName: string;
	branchName: string;
	builds: BuildV1[];
}

export interface BuildV1 {
	buildName: string;
	lastBuild: number;
}

export interface GlobalTestStagesV1RequestParams {
	appName: string;
	branchName?: string;
	periodFrom?: number;
	periodTo?: number;
	bsid?: string;
	withIntegrationStages?: boolean;
}

export interface TestStageV1 {
	id: number;
	isHidden: boolean;
	name: string;
}

export interface AggregatedTestStageV1 {
	id: number;
	isHidden: boolean;
	name: string;
}

export interface TestStageGlobalV1 {
	testStages: TestStageV1[];
	numOfStages: number;
	aggregatedTestStages: AggregatedTestStageV1[];
}
export interface ApplicationPagedApiRequestParams extends PagedApiRequestParams {
	periodFrom?: number;
	periodTo?: number;
}
export interface BranchPagedApiRequestParams extends PagedApiRequestParams {
	periodFrom?: number;
	appName?: string;
	itemsToProject: BranchProjection;
}

export const APP_DEFAULT_DROPDOWN_STATE = ({
	...pagedApiResponseDefaultState,
	sortBy: 'appName',
	sortDirection: 'asc',
	periodFrom: subYears(new Date(), 1).valueOf(),
	pageSize: 100,
	page: 1,
	search: null,
}) as ApplicationPagedApiRequestParams & DropdownPagedApiResponseDefaultState<ApplicationV4 & { disabled?: boolean }>;

export const BRANCH_DEFAULT_DROPDOWN_STATE = ({
	...pagedApiResponseDefaultState,
	sortBy: 'lastRun',
	sortDirection: 'asc',
	periodFrom: subYears(new Date(), 1).valueOf(),
	pageSize: 100,
	page: 1,
	search: null,
	appName: null,
	itemsToProject: BranchProjection.VISIBLE,
}) as BranchPagedApiRequestParams & DropdownPagedApiResponseDefaultState<BranchV2 & { disabled?: boolean }>;

export interface BranchRequest {
	appName: string;
	page: number;
	search: string;
	key?: string;
}

export interface AppRequest {
	page: number;
	search: string;
	key?: string;
}
