import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';

import { API_CONFIG, GeneralParams, PagedApiRequestParams, PagedListApiRequestParams, RequestAllowedHttpMethods } from '../models/api.model';


@Injectable({
	providedIn: 'root'
})
export class ApiService {
	readonly #apiBaseUrl = inject(APP_CONFIG)?.uri?.apiBaseUrl;
	readonly #httpClient = inject(HttpClient);


	getDataFromApi<T>(url: string, generalParams: GeneralParams, pagedParams: PagedApiRequestParams, httpMethodType: RequestAllowedHttpMethods): Observable<T> {
		switch (httpMethodType) {
			case 'get':
				return this.#getDataUsingGetMethod(url, pagedParams, generalParams);

			case 'post':
				return this.#getDataUsingPostMethod(url, pagedParams, generalParams);
		}
	}

	createPagedApiHttpParams(params: PagedApiRequestParams): HttpParams {
		let queryParams = new HttpParams();
		const page = params?.page ?? 1;
		const limit = params?.pageSize ?? API_CONFIG.pageSize;
		const offset = (page - 1) * limit;

		queryParams = queryParams.set('offset', offset.toString());
		queryParams = queryParams.set('limit', limit.toString());

		if (params?.sortBy) {
			queryParams = queryParams.set('sortBy', params.sortBy);
		}

		if (params?.sortDirection) {
			queryParams = queryParams.set('sortDirection', params.sortDirection);
		}

		if (params?.search) {
			queryParams = queryParams.set('search', params.search);
		}

		return queryParams;
	}


	// Use this for infinite scroll based features
	createPagedListApiHttpParams(params: PagedListApiRequestParams): HttpParams {
		let queryParams = new HttpParams();
		const limit = params.limit;
		const offset = params.offset;

		queryParams = queryParams.set('offset', offset.toString());
		queryParams = queryParams.set('limit', limit.toString());

		if (params?.sortBy) {
			queryParams = queryParams.set('sortBy', params.sortBy);
		}

		if (params?.sortDirection) {
			queryParams = queryParams.set('sortDirection', params.sortDirection);
		}

		if (params?.search) {
			queryParams = queryParams.set('search', params.search);
		}

		return queryParams;
	}

	createPagedApiRequestParams(params: PagedApiRequestParams): URLSearchParams {
		const queryParams = new URLSearchParams();
		const page = params?.page ?? 1;
		const limit = params?.pageSize ?? API_CONFIG.pageSize;
		const offset = (page - 1) * limit;

		queryParams.append('offset', offset.toString());
		queryParams.append('limit', limit.toString());

		if (params?.sortBy) {
			queryParams.append('sortBy', params.sortBy);
		}

		if (params?.sortDirection) {
			queryParams.append('sortDirection', params.sortDirection);
		}

		if (params?.search) {
			queryParams.append('search', params.search);
		}

		return queryParams;
	}

	appendRequestParams(paramsToAppend: GeneralParams, urlSearchParams: URLSearchParams): URLSearchParams {
		Object.keys(paramsToAppend).forEach((key: string) => {
			urlSearchParams.append(key, paramsToAppend[key] as string);
		});

		return urlSearchParams;
	}

	#getDataUsingGetMethod<T>(url: string, pagedParams: PagedApiRequestParams, generalParams: GeneralParams): Observable<T> {
		let queryParams = new URLSearchParams();

		if (pagedParams) {
			queryParams = this.createPagedApiRequestParams(pagedParams);
		}
		if (generalParams) {
			queryParams = this.appendRequestParams(generalParams, queryParams);
		}

		const queryParamsAsString = generalParams || pagedParams ? `?${queryParams.toString()}` : '';

		return this.#httpClient.get<T>(`${this.#apiBaseUrl}${url}${queryParamsAsString}`);
	}

	#getDataUsingPostMethod<T>(url: string, pagedparams: PagedApiRequestParams, generalParams: GeneralParams): Observable<T> {
		return this.#httpClient.post<T>(`${this.#apiBaseUrl}${url}`, { ...pagedparams, ...generalParams });
	}
}
